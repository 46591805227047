import React, { useEffect, useState } from "react";
import moment from "moment/min/moment-with-locales";
import { Translate } from "react-localize-redux";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { auth, getPopularTags } from "../../actions/user";
import { getReports, getReports2, getReportsForTag } from "../../actions/projects";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";

import Tag from "../../components/Tag/Tag";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Widget from "../../components/Widget/Widget";

import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';

import s from "./Reports.module.scss";

const Reports = (props) => {
    const [reports, setReports] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const formatter = new Intl.NumberFormat('ru', {})
    const [from, setFrom] = useState(moment().startOf('month').toDate());
    const [to, setTo] = useState(moment().endOf('month').toDate());
    // const [from, setFrom] = useState(undefined);
    // const [to, setTo] = useState(undefined);
    const modifiers = { start: from, end: to };
    const [toRef, setToRef] = useState(null);
    const [simple, setSimple] = useState(true);

    const [simpleReportSelectedTag, setSimpleReportSelectedTag] = useState(null);

    // const [series, setSeries] = useState([]);
    // const [categories, setCategories] = useState([]);

    // const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        props.dispatch(auth())

        if (localStorage.getItem('currentLanguageCode') == 'ru') {
            moment.locale('ru')
        }
    }, [])

    useEffect(() => {
        props.dispatch(getReports(props.projectId))
        props.dispatch(getPopularTags(props.projectId, true))
    }, [props.projectId])

    useEffect(() => {
        if (props.tags && props.tags.length > 0) {
            setSelectedTags([props.tags[0]])
        }
    }, [props.tags])

    useEffect(() => {
        if (simpleReportSelectedTag != null) {
            props.dispatch(getReportsForTag(props.projectId, simpleReportSelectedTag))
        } else {
            props.dispatch(getReports(props.projectId))
        }
    }, [simpleReportSelectedTag])

    useEffect(() => {
        if (from != undefined && to != undefined && selectedTags.length > 0) {
            props.dispatch(getReports2(props.projectId, selectedTags.map(tag => tag._id), moment(from).format("DD-MM-YYYY"), moment(to).format("DD-MM-YYYY")))
        }
    }, [from, to, selectedTags])

    useEffect(() => {
        if (props.reports && props.reports.length > 0) {
            let reports = props.reports.map(x => {
                x.date = moment(x.date, "MM/YYYY")
                return x;
            })
            reports.sort((a, b) => {
                return b.date.diff(a.date)
            })
            setReports(reports);
        } else {
            setReports([]);
        }
    }, [props.reports])

    useEffect(() => {
        console.log('reports', props.reports)
    }, [props.reports])

    function handleDayClick(selectedDay) {
        const range = DateUtils.addDayToRange(selectedDay, { from, to });
        setFrom(range.from);
        setTo(range.to);
    }

    function handleFromChange(date) {
        setFrom(date);
    }

    function handleToChange(date) {
        setTo(date);
    }

    function chartSettings() {
        return {
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 2,
            },
            xaxis: {
                type: "category",
                categories: props.categories,
                labels: {
                    style: {
                        colors: "#6B859E",
                        opacity: 0.7,
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ["#6B859E"],
                        opacity: 0.7,
                    },
                },
            },
            tooltip: {
                x: {
                    show: false,
                },
            },
            fill: {
                type: "solid"
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: true,
                horizontalAlign: "center",
            },
        };
    }

    const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
        first.toLocaleUpperCase(locale) + rest.join('')

    function renderMonth(date) {
        if (localStorage.getItem('currentLanguageCode') == 'ru') {
            moment.locale('ru')
        }

        return <div className={`p-2 ${s.date}`}>{capitalizeFirstLetter(date.format("MMMM"))}</div>
    }

    function renderSimpleReportTags() {
        return (
            <div className={`${s.gap10} d-flex flex-row flex-wrap mt-3 mb-3`}>
                {
                    props.tags && props.tags.map((tag, index) => {
                        if (tag.service) return null;

                        return (
                            <Tag key={index} isSelected={simpleReportSelectedTag == tag._id} tag={tag} title={tag.title} onClick={() => {
                                if (simpleReportSelectedTag == tag._id) {
                                    setSimpleReportSelectedTag(null)
                                } else {
                                    setSimpleReportSelectedTag(tag._id)
                                }
                            }} />
                        )
                    })
                }
            </div>
        )
    }

    function renderSimpleReports() {
        if (reports == null) return <div className={s.loading}><LoadingIndicator /></div>

        if (reports.length == 0) return <div className={s.noReports}><Translate id="reports.noReports" /></div>

        return reports.map((report, index) => {
            return (
                <div key={index}>
                    {/* {report.date} */}
                    {renderMonth(report.date)}

                    <div className={s.report}>
                        <span><Translate id="reports.records" /> {report.count} </span>
                        <span><Translate id="reports.income" /> <span className={s.reportValue}>{formatter.format(report.income)}</span></span>
                        <span><Translate id="reports.expense" /> <span className={s.reportValue}>{formatter.format(report.expense)}</span></span>
                        <span><Translate id="reports.balance" /> {report.income > (report.expence ?? 0) ? "+" : ""}<span className={s.reportValue}>{formatter.format(report.balance)}</span></span>
                    </div>
                </div>
            )
        })
    }

    function renderAdvancedReports() {
        return (
            <div>
                <h6><Translate id="reports.chooseTags" /></h6>
                {props.tags != null && props.tags.length > 0 ?
                    <div className={`${s.gap10} d-flex flex-row flex-wrap mt-3 mb-3`}>
                        {props.tags.map((tag, index) => {
                            return (
                                <Tag key={index} isSelected={selectedTags.includes(tag)} tag={tag} title={tag.title} onClick={() => toggleTag(tag)} />
                            )
                        })}
                    </div> : null
                }

                {/* <div className={`${s.dates} position-relative`}>
                            
                        </div> */}

                <h6><Translate id="reports.choosePeriod" /></h6>
                <div className="InputFromTo mt-3">
                    <DayPickerInput
                        localeUtils={MomentLocaleUtils}
                        value={from}
                        placeholder="From"
                        format="ll"
                        locale={localStorage.getItem('currentLanguageCode') == 'ru' ? 'ru' : 'en'}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { after: to },
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 2,
                            onDayClick: () => toRef && toRef.getInput && toRef.getInput().focus(),
                            localeUtils: MomentLocaleUtils,
                            locale: localStorage.getItem('currentLanguageCode') == 'ru' ? 'ru' : 'en',
                        }}
                        onDayChange={(date) => handleFromChange(date)}
                    />{' '}
                    —{' '}
                    <span className="InputFromTo-to">
                        <DayPickerInput
                            localeUtils={MomentLocaleUtils}
                            ref={el => (setToRef(el))}
                            value={to}
                            placeholder="To"
                            format="ll"
                            locale={localStorage.getItem('currentLanguageCode') == 'ru' ? 'ru' : 'en'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { before: from },
                                modifiers,
                                month: from,
                                fromMonth: from,
                                numberOfMonths: 2,
                                localeUtils: MomentLocaleUtils,
                                locale: localStorage.getItem('currentLanguageCode') == 'ru' ? 'ru' : 'en',
                            }}
                            onDayChange={(date) => handleToChange(date)}
                        />
                    </span>
                </div>

                <Widget className="widget-p-md">
                    <ApexCharts
                        options={chartSettings()}
                        series={props.series}
                        type="line"
                        height={300}
                    />
                </Widget>
            </div>
        )
    }

    function toggleTag(tag) {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(x => x != tag))
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    return (
        <>
            <div className={`${s.header} d-flex ${s.gap10} mb-4`}>
                <h3 className={`${simple ? s.selected : s.disabled}`} onClick={() => setSimple(true)}>
                    <Translate id="reports.simpleReport" />
                </h3>
                <h3 className={`${!simple ? s.selected : s.disabled} ml-3`} onClick={() => setSimple(false)}>
                    <Translate id="reports.advancedReport" />
                </h3>
            </div>

            {simple ?
                <>
                    {renderSimpleReportTags()}
                    {renderSimpleReports()}
                </>
                :
                renderAdvancedReports()
            }
        </>
    )
}

Reports.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        user: store.user.user,
        projectId: store.navigation.selectedProjectId,
        reports: store.projects.reports,
        tags: store.user.popularTags,
        series: store.projects.series,
        categories: store.projects.categories,
    };
}

export default withRouter(connect(mapStateToProps)(Reports));

import axios from 'axios';

import { 
    RECORD_CREATED
} from './user';

export const PROJECT_LOADED = 'PROJECT_LOADED';
export const INVITE_SENT = 'INVITE_SENT';
export const INVITE_CANCELLED = 'INVITE_CANCELLED';
export const RECORD_REMOVED = 'RECORD_REMOVED';
export const PROJECT_DELETED = 'PROJECT_DELETED';
export const REPORTS_LOADED = 'REPORTS_LOADED';
export const REPORTS_LOADED_2 = 'REPORTS_LOADED_2';
export const RECORD_HISTORY_LOADED = 'RECORD_HISTORY_LOADED';
export const PROJECT_LEFT = 'PROJECT_LEFT';
export function receiveProjectLoaded(project, invites) {
    return {
        type: PROJECT_LOADED,
        project,
        invites
    };
}

export function receiveProjectDeleted(projectId) {
    return {
        type: PROJECT_DELETED,
        projectId
    };
}

export function receiveInviteSent(invite, projectId) {
    return {
        type: INVITE_SENT,
        invite,
        projectId
    };
}

export function receiveInviteCancelled(inviteId, projectId) {
    return {
        type: INVITE_CANCELLED,
        inviteId,
        projectId
    };
}

export function receiveRecordCreated(record) {
    return {
        type: RECORD_CREATED,
        record,
    }
}

export function receiveRecordRemoved(recordId) {
    return {
        type: RECORD_REMOVED,
        recordId,
    }
}

export function receiveReports(reports) {
    return {
        type: REPORTS_LOADED,
        reports,
    }
}

export function receiveReports2(data, categories) {
    return {
        type: REPORTS_LOADED_2,
        series: data,
        categories,
    }
}

export function receiveRecordHistory(history) {
    return {
        type: RECORD_HISTORY_LOADED,
        history,
    }
}

export function receiveProjectLeft(projectId) {
    return {
        type: PROJECT_LEFT,
        projectId,
    }
}

export function getProject(id, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/get?token=${token}&projectId=${id}`;

        return axios.get(url)
            .then(response => {
                if (response.data.success) {
                    completion(response.data.project, response.data.tags);
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }
            });
    };
}

export function inviteToProject(projectId, email, type, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/invite`;

        return axios.post(url, {projectId, email, type, token, language: localStorage.getItem('currentLanguageCode')})
            .then(response => {
                if (response.data.success) {
                    completion(response.data.invite);
                    dispatch(receiveInviteSent(response.data.invite, projectId));
                }
            });
    }
}

export function resendInvite(projectId, inviteId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/invite/resend`;
        
        return axios.post(url, {projectId, inviteId, token, language: localStorage.getItem('currentLanguageCode') })
            .then(response => {
                if (response.data.success) {
                    completion(response.data.invite);
                    dispatch(receiveInviteSent(response.data.invite, projectId));
                }
            }
        );
    }
}

export function cancelInvite(projectId, inviteId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');

        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/invite/cancel`;

        return axios.post(url, {inviteId, projectId, token})
            .then(response => {
                if (response.data.success) {
                    completion();
                    dispatch(receiveInviteCancelled(inviteId, projectId));
                }
            }
        );
    }
}

export function createProject(projectName, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/create`;

        return axios.post(url, {projectName, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            });
    }
}

export function editProjectName(projectName, projectId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/edit`;

        return axios.post(url, {projectName, projectId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            }
        );
    }
}

export function setProjectShortName(shortName, projectId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/setShortName`;

        return axios.post(url, {shortName, projectId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion(response.data.error)
            }
        );
    }
}

export function editProjectAccess(projectId, accessId, type, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/changeAccess`;

        return axios.post(url, {projectId, accessId, type, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            }
        );
    }
}

export function removeProjectAccess(projectId, accessId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/removeAccess`;

        return axios.post(url, {projectId, accessId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            }
        );
    }
}

export function copyProjectRecord(recordId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/record/copy`;

        return axios.post(url, {recordId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveRecordCreated(response.data.record));
                }

                completion()
            }
        );
    }
}

export function removeProjectRecord(recordId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/record/remove`;

        return axios.post(url, {recordId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveRecordRemoved(recordId));
                }

                completion()
            }
        );
    }
}

export function deleteProject(projectId, complete) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/delete`;

        return axios.post(url, {projectId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectDeleted(projectId));
                }

                complete(response.data.success)
            }
        );
    }
}

export function getReports(projectId) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/getReports`;

        return axios.post(url, {projectId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveReports(response.data.result));
                }
            }
        );
    }
}

export function getReportsForTag(projectId, tagId) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/simpleReportForTag`;

        return axios.post(url, {projectId, tagId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveReports(response.data.result));
                }
            }
        );
    }
}

export function getReports2(projectId, tags, from, to) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/getReports2`;

        return axios.post(url, {projectId, token, startDate: from, endDate: to, tags})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveReports2(response.data.data, response.data.categories));
                }
            }
        );
    }
}

export function getRecordHistory(recordId) {
    return dispatch => {
        dispatch(receiveRecordHistory([]));

        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/record/getHistory`;

        return axios.post(url, {recordId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveRecordHistory(response.data.history));
                }
            }
        );
    }
}

export function acceptProjectInvite(inviteId, notificationId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/invite/accept`;

        return axios.post(url, {inviteId, notificationId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            }
        );
    }
}

export function declineProjectInvite(inviteId, notificationId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/invite/decline`;

        return axios.post(url, {inviteId, notificationId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectLoaded(response.data.project, response.data.invites));
                }

                completion()
            }
        );
    }
}

export function leaveProject(projectId, completion) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/leave`;

        return axios.post(url, {projectId, token})
            .then(response => {
                if (response.data.success) {
                    dispatch(receiveProjectDeleted(projectId));
                }

                completion()
            }
        );
    }
}
import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment/min/moment-with-locales";

import { Button, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, FormText, Label, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

import { auth, selectProject, getRecordsForProject, getRecordsForProjectByOffset, createRecord, getPopularTags } from "../../actions/user"
import { copyProjectRecord, removeProjectRecord } from '../../actions/projects.js';

import ProjectRecord from "../../components/ProjectRecord/ProjectRecord.js"
import CreateRecord from "../../components/CreateRecord/CreateRecord.js"
import RecordHistory from "../../components/RecordHistory/RecordHistory.js"

import s from "./Dashboard.module.scss";
import tutorialArrow from '../../assets/dashboard/tutorial-arrow.svg'

import Fade from 'react-reveal/Fade';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
// import useInfiniteScroll  from "../../tools/useInfiniteScroll";

const useInfiniteScroll = (callback) => {
    const [shouldFetch, setShouldFetch] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (shouldFetch) {
            callback();
            setShouldFetch(false);
        }
    }, [shouldFetch]);

    function handleScroll() {
        // let bottomOfPage = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
        // let bottomOfPage = document.body.offsetHeight - document.documentElement.scrollTop < Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
        let bottomOfPage = window.innerHeight + window.pageYOffset == document.body.scrollHeight

        if (!bottomOfPage) return;

        console.log("FETCH !!!")
        // callback(offset);
        setShouldFetch(true);
    }
};

const Dashboard = (props) => {
    const [openedIndex, setOpenedIndex] = useState(null);
    const [isCreateOpened, setIsCreateOpened] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [deletingRecordId, setDeletingRecordId] = useState(null);
    const [deletingRecordInProgressId, setDeletingRecordInProgressId] = useState(null);
    const [project, setProject] = useState(null);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);
    const groupProps = {
        appear: false,
        enter: false,
        exit: true,
    }

    const [isFetching, setIsFetching] = useState(false);

    const fetchMoreListItems = () => {
        if (!props.hasMoreRecords || isFetching) return;

        setIsFetching(true);
        props.dispatch(getRecordsForProjectByOffset(props.projectId, props.records.length))
    }

    const history = useHistory();

    useInfiniteScroll(fetchMoreListItems);

    useEffect(() => {
        props.dispatch(auth())
    }, [])

    useEffect(() => {
        props.dispatch(auth())

        let projectId = props.projectId;

        if (projectId != null && projectId != "") {
            let project = props.projects.find(project => project._id == projectId || project.shortName == projectId);
            setProject(project);

            setIsFetching(true);
            setIsCreateOpened(false);
            setOpenedIndex(null);

            fetchMoreListItems();
            props.dispatch(getPopularTags(projectId, true))
            setProjectId(projectId)
        } else {
            // history.push("/app/dashboard");
        }
    }, [props.projectId])

    useEffect(() => {
        if (props.projectId != null && props.projectId != "") {
            let project = props.projects.find(project => project._id == projectId || project.shortName == projectId);
            setProject(project);
        }
    }, [props.projects])

    useEffect(() => {
        if (props.records.length > 0 || !props.hasMoreRecords) {
            setIsFetching(false);
        }
    }, [props.records])

    const createRecordAction = (record) => {
        props.dispatch(createRecord(props.projectId, record))
    }

    function renderDate(timestamp, previosTimestamp) {
        let date = moment(timestamp)

        if (localStorage.getItem('currentLanguageCode') == 'ru') {
            moment.locale('ru')
        }

        if (previosTimestamp == null) {
            if (date.day() == moment().day()) {
                return (
                    <div className={`p-2 ${s.dateLabel}`}> <Translate id="records.today" /> </div>
                )
            }

            return (
                <div className={`p-2 ${s.dateLabel}`}> {date.format("D MMMM")} </div>
            )
        }

        let previosDate = moment(previosTimestamp)
        if (date.day() != previosDate.day()) {
            return (
                <div className={`p-2 ${s.dateLabel}`}> {date.format("D MMMM")} </div>
            )
        }

        return null
    }

    function renderCreateRecordButton() {
        if (isEditor()) {
            return (
                <CreateRecord key="create-record" projectId={props.projectId} popularTags={props.popularTags} dispatch={props.dispatch} isOpened={isCreateOpened} onOpen={(opned) => {
                    setIsCreateOpened(opned)
                    setOpenedIndex(null)
                }} />
            )
        }

        return (
            <div className={s.readOnlyBanner}>
                For this project you have only read access.
            </div>
        )
    }

    function renderTutorialIfNeeded() {
        if (props.records.length > 0) {
            return <></>
        }

        return (
            <div className={s.tutorial}>
                <img src={tutorialArrow} className={s.tutorialArrow} />
                <div className={s.tutorialTitle}>
                    <Translate id="records.tutorial-title" />
                </div>
                <div className={s.tutorialDescription}>
                    <Translate id="records.tutorial-description" />
                </div>
            </div>
        )
    }

    function isEditor() {
        if (props.projects != undefined && props.projects.length > 0) {
            let project = props.projects.find(p => p._id == props.projectId || p.shortName == props.projectId)

            if (project == undefined) {
                return false
            }

            if (props.user._id == undefined) {
                return false
            }

            let projectAccess = project.access.find(x => x.user._id.toString() == props.user._id.toString())

            if (projectAccess != null && projectAccess != undefined && (projectAccess.access == "admin" || projectAccess.access == "write")) {
                return true
            }
        }

        return false
    }

    const renderDeleteRecordModal = () => {
        var isDeletingRecord = deletingRecordId != null;
        return (
            <Modal isOpen={isDeletingRecord} key="deleteRecord">
                <ModalHeader>
                    <Translate id="records.delete_record_title" />
                </ModalHeader>
                <ModalFooter >
                    <Button color="secondary" onClick={() => setDeletingRecordId(null)}><Translate id="records.cancel" /></Button>
                    <Button color="danger" onClick={() => deleteRecordAction()}><Translate id="records.delete" /></Button>
                </ModalFooter>
            </Modal>
        )
    }

    function onDeleteRecord(recordId) {
        setDeletingRecordId(recordId)
    }

    function deleteRecordAction() {
        setDeletingRecordInProgressId(deletingRecordId)

        props.dispatch(removeProjectRecord(deletingRecordId, () => {
            setOpenedIndex(null)
            setDeletingRecordId(null)
        }))

        setDeletingRecordId(null)
    }

    var key = 0;

    return (
        (project == null) ? <div className={s.loading}><LoadingIndicator /></div> :
            (
                <div>
                    {renderCreateRecordButton()}
                    {
                        props.records.map((record, index) => (
                            <div key={key++}>
                                {
                                    renderDate(Date.parse(record.date), index > 0 ? Date.parse(props.records[index - 1].date) : null)
                                }
                                <Fade key={record._id} when={record._id !== deletingRecordInProgressId} right>
                                    <ProjectRecord key={record._id} record={record} projectId={props.projectId} dispatch={props.dispatch} popularTags={props.popularTags} isOpened={openedIndex === index && record._id !== deletingRecordInProgressId} onOpen={(isOpened) => {
                                        if (isOpened) {
                                            setIsCreateOpened(false)
                                            setOpenedIndex(index)
                                        } else if (openedIndex === index) {
                                            setOpenedIndex(null)
                                        }
                                    }} canEdit={isEditor()} onDelete={(recordId) => onDeleteRecord(recordId)} onOpenHistory={() => setIsHistoryOpened(true)} />
                                </Fade>
                            </div>
                        ))
                    }
                    {props.records.length == 0 && !isCreateOpened && !isFetching ? renderTutorialIfNeeded() : null}

                    {isFetching ? <div className={s.centerLoading}> <LoadingIndicator /> </div> : null}
                    {renderDeleteRecordModal()}

                    <RecordHistory history={props.history} isOpened={isHistoryOpened} onClose={() => setIsHistoryOpened(false)} />
                </div>
            )
    )
}

Dashboard.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        user: store.user.user,
        projects: store.user.projects,
        selectedProjectIndex: store.user.selectedProjectIndex,
        records: store.user.records,
        popularTags: store.user.popularTags,
        projectId: store.navigation.selectedProjectId,
        hasMoreRecords: store.user.hasMoreRecords,
        history: store.projects.history,
    };
}

export default withRouter(connect(mapStateToProps)(Dashboard));

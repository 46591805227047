import {
    UPDATE_USER,
    PROJECTS_UPDATE,
    RECORDS_UPDATE,
    RECORD_CREATED,
    RECORD_UPDATED,
    POPUPLAR_TAGS_UPDATE,
    TAG_CREATED,
    FIND_RECORDS_UPDATED,
    SELECT_PROJECT,
    SEARCH_PROJECT_RECORDS_ERROR
} from "../actions/user.js";

import {
    PROJECT_LOADED,
    RECORD_REMOVED,
    PROJECT_DELETED
} from "../actions/projects.js"

const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    projects: [],
    selectedProjectIndex: 0,
    records: [],
    popularTags: [],
    isProjectEmpty: false,
    hasMoreRecords: true,
    projectId: localStorage.getItem('selectedProjectId') ?? window.location.pathname.split("/").pop() ?? null
};

export default function runtime(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER:
            return Object.assign({}, state, {
                user: action.user,
            });

        case PROJECTS_UPDATE:
            return Object.assign({}, state, {
                projects: action.projects,
            });

        case PROJECT_LOADED:
            let projects = state.projects;

            let projectIndex = projects.findIndex(project => project._id === action.project._id)
            if (projectIndex === -1) {
                projects.push(action.project);
            } else {
                projects[projectIndex] = action.project;
            }

            return Object.assign({}, state, {
                projects: projects,
            });

        case PROJECT_DELETED:
            return Object.assign({}, state, {
                projects: state.projects.filter(project => project._id !== action.projectId),
            });

        case RECORDS_UPDATE:
            let records = state.records.map(record => {
                let index = action.records.findIndex(record2 => record2._id === record._id)
                if (index !== -1) {
                    return action.records[index]
                }

                return record
            });
            let newRecords = action.records.filter(record => records.findIndex(record2 => record2._id === record._id) === -1);
            records = [...records, ...newRecords];

            return Object.assign({}, state, {
                records: records,
                hasMoreRecords: action.hasMore,
            });

        case RECORD_CREATED:
            let newRecordsSortedByDate = [action.record, ...state.records].sort((a, b) => {
                return new Date(a.date) > new Date(b.date) ? -1 : 1;
            })

            return Object.assign({}, state, {
                records: newRecordsSortedByDate,
            });

        case RECORD_REMOVED:
            return Object.assign({}, state, {
                records: state.records.filter(record => record._id !== action.recordId),
            });

        case RECORD_UPDATED:
            let updatedRecords = state.records.map(record => {
                if (record._id === action.record._id) {
                    return action.record;
                }
                return record;
            })
            let a = updatedRecords.sort((a, b) => {
                return new Date(a.date) > new Date(b.date) ? -1 : 1;
            })
            console.log(a)
            return Object.assign({}, state, { records: updatedRecords })
        case POPUPLAR_TAGS_UPDATE:
            return Object.assign({}, state, {
                popularTags: action.tags,
            });

        case TAG_CREATED:
            if (state.popularTags.filter(x => x._id === action.tag._id).length > 0) {
                return state
            }

            return Object.assign({}, state, {
                popularTags: [...state.popularTags, action.tag],
            });
        case FIND_RECORDS_UPDATED:
            return Object.assign({}, state, {
                records: action.records,
                hasMoreRecords: true,
                isProjectEmpty: false
            });
        case SELECT_PROJECT:
            if (state.projectId === action.projectId) return state;

            return Object.assign({}, state, {
                selectedProjectIndex: action.index,
                records: [],
                hasMoreRecords: true,
                isProjectEmpty: false,
                projectId: action.projectId
            });
        case SEARCH_PROJECT_RECORDS_ERROR:
            return Object.assign({}, state, {
                isProjectEmpty: true
            });

        default:
            return state;
    }
}

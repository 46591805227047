import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tag from '../Tag/Tag.js'
import { Translate } from "react-localize-redux";

import { uploadFile, editRecord, createTag, createRecord } from '../../actions/user.js';

import s from './CreateRecord.module.scss';
import { FormText, Input, Tooltip } from 'reactstrap';

import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator.js'
import filesHelpIcon from '../../assets/files-help.svg'

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}

const CreateRecord = (props) => {
    const {
        record = {},
        className = '',
        popularTags = [],
        _isOpened = props.isOpened,
        ...restProps
    } = props;

    const [isOpened, setOpened] = useState(false);
    const [draftFiles, setDraftFiles] = useState([]);
    const [recordDraft, setRecordDraft] = useState({
        title: "",
        comment: "",
        type: "income",
        amount: null,
        tags: [],
        documents: [],
        images: [],
        date: new Date().toISOString().split('T')[0]
    });
    const [isCreatingTag, setCreatingTag] = useState(false);
    const [inputRef] = useFocus();
    const [tagDraft, setTagDraft] = useState('');
    const [fileErrors, setFileErrors] = useState([]);
    const [creationError, setCreationError] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [createdTag, setCreatedTag] = useState(null);
    const [isTultipOpened, setTultipOpened] = useState(false);

    const formatter = new Intl.NumberFormat('ru', { maximumFractionDigits: 2, minimumFractionDigits: 0, style: 'currency', currency: 'RUB' })

    useEffect(() => {
        if (_isOpened != null) {
            setOpened(_isOpened);
        }
    }, [_isOpened]);

    useEffect(() => {
        console.log(draftFiles)

        if (draftFiles.length > 0) {
            if (draftFiles.find(draft => draft.isUploading === true)) {
                return
            }

            innerUploadFile(draftFiles[0].file, draftFiles[0].id)
        }
    }, [draftFiles]);

    useEffect(() => {
        if (uploadedFile != null) {
            if (uploadedFile.type == 'document') {
                setRecordDraft({ ...recordDraft, documents: [uploadedFile.file, ...recordDraft.documents] })
            } else {
                setRecordDraft({ ...recordDraft, images: [uploadedFile.file, ...recordDraft.images] })
            }

            setUploadedFile(null)
        }
    }, [uploadedFile])

    useEffect(() => {
        if (createdTag != null) {
            setRecordDraft({ ...recordDraft, tags: [...recordDraft.tags, createdTag] })
            setCreatedTag(null)
        }
    }, [createdTag])

    function toggleOpened() {
        if (isOpened) {
            return
        }

        props.onOpen(!isOpened)
        setOpened(!isOpened)
    }

    function changeTitle(event) {
        setCreationError(null)
        setRecordDraft({ ...recordDraft, title: event.target.value })
    }

    function changeDescription(event) {
        setRecordDraft({ ...recordDraft, comment: event.target.value })
    }

    function changeAmount(event) {
        setRecordDraft({ ...recordDraft, amount: event.target.value })
    }

    function filesUploadContainerChanged(event) {
        event.preventDefault();

        console.log(event)

        let files = [];
        if (event.dataTransfer != null && event.dataTransfer.files.length > 0) {
            files = event.dataTransfer.files;
        } else if (event.target.files.length > 0) {
            files = event.target.files;
        }

        if (files.length === 0) {
            return
        }

        let drafts = []
        let errors = []
        for (let file of files) {
            console.log(file, file.size)

            if (file.size > 10000000) {
                errors.push(file.name)
                continue
            }

            let randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            drafts.push({
                id: randomId,
                file,
                isDownloaded: false,
                isUploading: false,
                progress: 0,
            })
        }

        setFileErrors(errors)
        let currentCount = recordDraft.documents.length + recordDraft.images.length;
        let maxCount = 5;
        drafts = drafts.slice(0, maxCount - currentCount)

        setDraftFiles([...drafts, ...draftFiles])
        event.target.value = null
    }

    function innerUploadFile(file, id) {
        console.log('start upload', id)
        var draft = draftFiles.find(draft => draft.id === id);
        if (draft == null) {
            return
        }
        draft.isUploading = true;
        draft.progress = 0;

        let newDrafts = draftFiles.map(draft => {
            if (draft.id === id) {
                return draft
            }
            return draft
        })
        setDraftFiles(newDrafts)


        props.dispatch(uploadFile(file, response => {
            setUploadedFile(response)
            setDraftFiles(draftFiles.filter(f => f.id !== id));
        }))
    }

    function changeType(event) {
        if (event.target.id === 'income') {
            setRecordDraft({ ...recordDraft, type: 'income' })
        } else {
            setRecordDraft({ ...recordDraft, type: 'expense' })
        }
    }

    function closeSelf() {
        setCreationError(null)
        props.onOpen(false)
        setOpened(false)
    }

    function toggleTooltip() {
        if (!isTultipOpened) {
            setTimeout(() => {
                setTultipOpened(false)
            }, 3000)
        }

        setTultipOpened(!isTultipOpened)
    }

    function saveRecord() {
        // createRecord(recordDraft)
        props.dispatch(createRecord(props.projectId, recordDraft, (error) => {
            if (error != null && error != undefined) {
                setCreationError(error)
            } else {
                cancel()
            }
        }))
    }

    function cancel() {
        setRecordDraft({
            title: "",
            comment: "",
            type: "income",
            amount: null,
            tags: [],
            documents: [],
            images: []
        })
        closeSelf()
    }

    function createTagAction() {
        props.dispatch(createTag(tagDraft, props.projectId, (response) => {
            console.log(Date.now(), response, recordDraft)
            setCreatingTag(false)
            setTagDraft('')
            setCreatedTag(response)
        }))
    }

    function toggleTagSelection(tag) {
        if (recordDraft.tags.find(t => t._id === tag._id) != null) {
            setRecordDraft({ ...recordDraft, tags: recordDraft.tags.filter(t => t._id !== tag._id) })
        } else {
            setRecordDraft({ ...recordDraft, tags: [...recordDraft.tags, tag] })
        }

        console.log(Date.now(), recordDraft)
    }

    function removeImageAtIndex(index) {
        const images = [...recordDraft.images]
        images.splice(index, 1)
        setRecordDraft({ ...recordDraft, images })
    }

    function removeFromDocuments(index) {
        let newDocuments = recordDraft.documents.slice()
        newDocuments.splice(index, 1)
        setRecordDraft({ ...recordDraft, documents: newDocuments })
    }

    function renderEmpty() {
        let count = draftFiles.length + recordDraft.images.length + recordDraft.documents.length + 1
        let maxCount = 5;
        if (count >= maxCount) {
            return
        }

        var array = [...Array(maxCount - count).keys()]
        return (
            <>
                {array.map((item, index) => {
                    return (
                        <div className={`${s.emptyFile}`} key={index}>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className={`${s.body} ${className} ${isOpened ? '' : s.pointer} ${isOpened ? s.grayBackground : s.blueBackground} d-flex flex-column flex-space justify-content-between`} onClick={toggleOpened}>
            {!isOpened ? (
                <div className={`${s.addRecord} ${isOpened ? 'd-none' : 'd-block'}`} onClick={() => { setOpened(true) }} >
                    <Translate id="records.add_new" />
                </div>
            ) : (

                <div className={`flex-column w-100 ${s.gap10} ${isOpened ? 'd-flex' : 'd-none'}`}>
                    {
                        creationError != null ? (
                            <div className={`${s.uploadErroContainer}`}>
                                <i className={`eva eva-close-outline ${s.closeIcon}`} onClick={() => {
                                    setCreationError(null)
                                }} />
                                <span className={s.uploadErrorTitle}><Translate id="records.error_create_title" /></span>
                                <br />
                                <span className={s.uploadErrorDescription}>{
                                    <Translate>
                                        {({ translate }) =>
                                            translate("records." + creationError)
                                        }
                                    </Translate>
                                }</span>
                            </div>
                        ) : null
                    }

                    <FormText><Translate id="records.title" /></FormText>
                    <Translate>
                        {({ translate }) =>
                            <Input
                                id="title"
                                className="input-transparent pl-3 w-100"
                                value={recordDraft.title}
                                onChange={(event) => changeTitle(event)}
                                type="text"
                                required
                                name="Title"
                                placeholder={translate('records.title_placeholder')}
                                required
                            />
                        }
                    </Translate>

                    <FormText><Translate id="records.description" /></FormText>
                    <Translate>
                        {({ translate }) =>
                            <Input
                                id="description"
                                style={{ minHeight: '50px' }}
                                className="input-transparent pl-3 w-100"
                                value={recordDraft.comment}
                                onChange={(event) => changeDescription(event)}
                                type="textarea"
                                required
                                name="Description"
                                placeholder={translate('records.description_placeholder')}
                            />
                        }
                    </Translate>

                    <FormText><Translate id="records.amount" /></FormText>
                    <Translate>
                        {({ translate }) =>
                            <Input
                                id="amount"
                                className="input-transparent pl-3 w-100"
                                value={recordDraft.amount == null ? "" : recordDraft.amount}
                                onChange={(event) => changeAmount(event)}
                                type="number"
                                required
                                name="Amount"
                                placeholder={translate('records.amount_placeholder')}
                                required
                            />
                        }
                    </Translate>

                    <FormText><Translate id="records.type" /></FormText>
                    <div className="d-flex flex-row">
                        <label className="mr-4" htmlFor="income">
                            <Input className="ml-0 position-relative mr-2" type="radio" name="type" id="income" title="Income" checked={recordDraft.type == 'income'} onChange={changeType} />
                            <span><Translate id="records.income" /></span>
                        </label>
                        <label htmlFor="expense">
                            <Input className="ml-0 position-relative mr-2" type="radio" name="type" id="expense" title="Expense" checked={recordDraft.type == 'expense'} onChange={changeType} />
                            <span><Translate id="records.expense" /></span>
                        </label>
                    </div>

                    <FormText><Translate id="records.tags" /></FormText>
                    <div className={`d-flex flex-row flex-wrap ${s.gap10}`}>
                        {
                            popularTags.map((tag, index) => (
                                <Tag key={index} tag={tag} title={tag.title} isSelected={
                                    recordDraft.tags.find(t => t._id == tag._id) != null
                                } onClick={() => { toggleTagSelection(popularTags[index]) }} />
                            ))
                        }
                        {
                            recordDraft.tags.filter(t => popularTags.find(pt => pt._id == t._id) == null).map((tag, index) => (
                                <Tag key={index} tag={tag} title={tag.title} isSelected={true} onClick={() => { toggleTagSelection(tag) }} />
                            ))
                        }

                        <Translate>
                            {({ translate }) =>
                                <Tag className={`${isCreatingTag ? "d-none" : "d-block"}`} key="new" tag={{ title: 'New tag' }} title={translate('records.create_tag')} onClick={() => {
                                    setCreatingTag(true)
                                    console.log(inputRef.current)
                                    setTimeout(() => {
                                        inputRef.current && inputRef.current.focus()
                                    }, 100)
                                }} />
                            }
                        </Translate>

                        <form className={`${s.inputSizer} ${isCreatingTag ? "d-inline-grid" : "d-none"}`} data-value={tagDraft} onSubmit={(e) => {
                            e.preventDefault();
                            createTagAction()
                        }}>
                            <input id="tag-input" value={tagDraft} ref={inputRef} className={`${s.tagInput}`} key="tag-input" type="text" onBlur={(e) => {
                                setCreatingTag(false)
                            }} onChange={(e) => {
                                setTagDraft(e.target.value)
                            }} />
                            <i className={`eva eva-plus-square-outline ${s.addTagButton} ${s.pointer}`} onMouseDown={(e) => { e.preventDefault(); }
                            } onClick={(e) => {
                                e.preventDefault();
                                createTagAction()
                            }} />
                        </form>
                    </div>

                    <FormText>
                        <Translate id="records.files" />
                        <img id="filesDescription" className={s.filesHelp} src={filesHelpIcon} onClick={() => toggleTooltip()} />
                        <Tooltip target="filesDescription" toggle={function noRefCheck() { }} isOpen={isTultipOpened}>
                            <Translate id="records.upload_files_description" />
                        </Tooltip>
                    </FormText>

                    {
                        fileErrors.map((error, index) => (
                            <div key={index} className={`${s.uploadErroContainer}`}>
                                <i className={`eva eva-close-outline ${s.closeIcon}`} onClick={() => {
                                    setFileErrors(fileErrors.filter((e, i) => i !== index))
                                }} />
                                <span className={s.uploadErrorTitle}><Translate id="records.error_upload_file_title" data={{ "file_name": error }} /></span>
                                <br />
                                <span className={s.uploadErrorDescription}><Translate id="records.error_upload_file_description" /></span>
                            </div>
                        ))
                    }

                    <div className={`d-flex flex-row ${s.gap10} ${s.allFilesContainer} noScrollBar`}>
                        {draftFiles.length + recordDraft.images.length + recordDraft.documents.length < 5 ?
                            <div className={`${s.filesUploadContainer}`}>
                                <Input type="file" multiple={true} className={`${s.fileUpload} w-100 h-100`} onChange={(event) => { filesUploadContainerChanged(event) }} onDrop={(event) => { filesUploadContainerChanged(event) }} />
                                <div className={s.dropZoneOverlay}>
                                    <span className={s.addFileButton}><Translate id="records.add_file" /></span>
                                    {/* <Translate id="records.files_area_hint" /> */}
                                    <p className={s.addFileDescription}><Translate id="records.or_drop" /></p>
                                </div>
                            </div> : null
                        }

                        {draftFiles.map((file, index) => (
                            <div key={index} className={`${s.file} d-flex justify-content-center align-items-center`} title={file.name}>
                                <LoadingIndicator />
                            </div>
                        ))}

                        {recordDraft.images.map((image, index) => (
                            <div key={image._id} className={`${s.file} `} title={image.name}>
                                <a href={image.url} target="_blank" className={`w-100 h-100 d-flex`}>
                                    <img src={image.url} className={`w-100`} />
                                </a>
                                <button className={`${s.removeAttachmentButton} btn`} onClick={() => { removeImageAtIndex(index) }}> <i className={`eva eva-trash-2-outline ${s.removeAttachmentButtonIcon}`} /> </button>
                            </div>
                        ))}

                        {recordDraft.documents.map((file, index) => (
                            <div key={index} className={`${s.file}`} title={file.name}>
                                <a href={file.url} target="_blank" className={`${s.fileLink} w-100 h-100`}>
                                    {file.name.split('.').slice(-1)[0].toUpperCase()}
                                    <p>{file.name}</p>
                                </a>
                                <button className={`${s.removeAttachmentButton} btn`} onClick={() => { removeFromDocuments(index) }}> <i className={`eva eva-trash-2-outline ${s.removeAttachmentButtonIcon}`} /> </button>
                            </div>
                        ))}

                        {renderEmpty()}
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        rowGap: '10px',
                        columnGap: '10px',
                        alignItems: 'flex-start',
                        maxHeight: '22px',
                    }}>
                        <FormText> 
                            <Translate id="records.date" />
                        </FormText>
                        <Input
                            type="date"
                            style={{
                                border: 'none',
                                borderRadius: '0',
                                padding: '0',
                                margin: '0',
                                backgroundColor: 'transparent',
                                color: '#000',
                                cursor: 'pointer',
                                outline: 'none',
                                boxShadow: 'none',
                                padding: 0,
                                margin: 0,
                                height: "16px",
                                fontSize: "14px"

                            }}
                        value={recordDraft.date || ''}
                        onChange={(e) => {
                            setRecordDraft({ ...recordDraft, date: e.target.value });
                        }}
                        />
                    </div>

                    <div className="d-flex flex-row justify-content-between mt-2">
                        <div className={`d-flex flex-column justify-content-between ${s.additionalText}`}>
                        </div>
                        <div className={`d-flex flex-row justify-content-end ${s.gap10}`}>
                            <button className="btn btn-secondary" onClick={cancel}><Translate id="records.cancel" /></button>
                            <button className="btn btn-primary" onClick={saveRecord}><Translate id="records.create" /></button>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    )
}

CreateRecord.propTypes = {
    dispatch: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    className: PropTypes.string,
    popularTags: PropTypes.array,
    isOpened: PropTypes.bool,
    onOpen: PropTypes.func,
}

export default CreateRecord;
